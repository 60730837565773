/* eslint-disable react/no-children-prop */
import {
  Alert,
  AlertTitle,
  AlertDescription,
  Select,
  Badge,
  Button,
  InputGroup,
  InputRightElement,
  Icon,
  Text,
} from '@chakra-ui/react';
import { useState, useRef } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { Form, InputField, SelectField } from '@/components/Form';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { useAuth } from '@/lib/auth';

import { updateNamedExports } from 'typescript';
import { type } from 'os';
import md5 from 'md5';
import { usePharma } from '@/providers/PharmaProvider';

const schema = z.object({
  u: z.string().min(1, 'Required'),
  p: z.string().min(1, 'Required'),
  sub_user: z.string().optional(),
});

type LoginValues = {
  u: string;
  p: string;
  sub_user?: string;
  is_hashed?: boolean;
};

type LoginFormProps = {
  onSuccess: () => void;
};

const PharmaUserFlags = ['PHARMA1_CRC', 'PHARMA1_PV'];

export const CRCForm = ({ onSuccess }: LoginFormProps) => {
  const { login, isLoggingIn, logout } = useAuth();
  const {
    setUser,
    setSubUsers,
    selectedSubUser,
    setSelectedSubUser,
    setFlags,
    setIsCRC,
    setIsPV,
    user,
    subUsers,
    flags,
    pharmaLogout,
    isCRC,
  } = usePharma();
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // const [subUsers, setSubUsers] = useState<{ user_id: number; user_name: string }[]>([]);

  if (!isCRC)
    return <Navigate to="/" />


  return (
    <div>


      <h2 className="text-lg">

      </h2>

      <Form<LoginValues, typeof schema>

        onSubmit={async (values) => {
          values.is_hashed = true;

          const login_data = await login(values);
          // login(values).then((login_data) => {
          //   console.log("login_data", login_data);
          //   if (login_data)
          //     onSuccess();
          // }).catch((err) => {
          //   setError(err);
          // });
          try {
            if (login_data) onSuccess();
          } catch (err: any) {
            setError(err);
          }
        }}
        className="w-full"
        schema={schema}
      >
        {({ register, formState, setValue }) => (
          <>
            {error && (
              <Alert
                status="error"
                className="text-center"
                rounded="md"
                textAlign="center"
                display="block"
              >
                <AlertTitle mr={2}>Oops!</AlertTitle>
                <AlertDescription>There was a problem trying to access the Site</AlertDescription>
              </Alert>
            )}
            {/* {subUsers.length === 0 ? (
              <>
                <InputField
                  size="lg"
                  type="text"
                  label="User Name"
                  error={formState.errors['u']}
                  registration={register('u')}
                />
                <InputField
                  size="lg"
                  type="password"
                  label="Password"
                  error={formState.errors['p']}
                  registration={register('p')}
                />
              </>
            ) : ( */}
            <>
              <InputField
                type="hidden"
                size="lg"
                error={formState.errors['u']}
                registration={register('u')}
                initialVal={user?.user_name}
              />
              <InputField
                type="hidden"
                size="lg"
                error={formState.errors['p']}
                registration={register('p')}
                initialVal={user?.p}
              />
            </>
            {/* <SelectField
              size="lg"
              options={subUsers.map((subUser) => ({
                label: subUser.user_name,
                value: subUser.user_id,
              }))}
              label="Select a Site"
              registration={register('sub_user')}
            ></SelectField> */}
            <Text size="lg">Select a Site</Text>
            <AutoComplete
              openOnFocus
              onChange={(value) => {
                setValue('sub_user', value);
              }}
            >
              {({ isOpen }) => (
                <>
                  <InputGroup>
                    <AutoCompleteInput variant="filled" placeholder="Search..." width="full" />
                    <InputRightElement
                      className="pointer-events-none"
                      children={<Icon as={isOpen ? ChevronUpIcon : ChevronDownIcon} />}
                    />
                  </InputGroup>
                  <AutoCompleteList>
                    {subUsers.map((subUser) => (
                      <AutoCompleteItem
                        key={`option-${subUser.user_id}`}
                        value={subUser.user_id.toString()}
                        label={subUser.user_name}
                      >
                        {subUser.user_name}
                      </AutoCompleteItem>
                    ))}
                  </AutoCompleteList>
                </>
              )}
            </AutoComplete>
            {/* )} */}
            <div className="flex flex-col">
              <Button isLoading={isLoggingIn} type="submit" w="full" size="lg" colorScheme="blue">
                {subUsers.length === 0 ? 'Log in' : 'Continue'}
              </Button>
            </div>
          </>
        )}
      </Form>
      {/* {subUsers.length !== 0 && ( */}
      {/* <Link to="/devices" className="font-medium text-blue-600 hover:text-blue-500 w-full">
        <Button className="w-full mt-3" size="lg">
          Manage Devices
        </Button>
      </Link> */}
      <div className="text-xs text-slate-500 mt-8 text-center">
        Currently logged in as {user?.user_name} as a CRC user
      </div>
      <Button variant="ghost" size="lg" w="full" colorScheme="blue" onClick={async () => {
        await logout().then(() => {
          pharmaLogout();
        });

        navigate('/');
      }}>
        Logout
      </Button>

    </div>
  );
};
