import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';

import { Patient } from '../types';

export const getPatients = (): Promise<Patient[]> => {
  return axios.get(`/vue_api/get_patients`);
};

type UsePatientsOptions = {
  config?: QueryConfig<typeof getPatients>;
};

export const usePatients = ({ config }: UsePatientsOptions = {}) => {
  return useQuery({
    ...config,
    queryKey: ['patients'],
    queryFn: () => getPatients(),
    refetchInterval: 10000,
  });
};
